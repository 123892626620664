import "./App.css";
import { ReactComponent as WorkIcon } from "./work.svg";
import { ReactComponent as SchoolIcon } from "./school.svg";

import timelineElements from "./timelineElements";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

import "react-vertical-timeline-component/style.min.css";

function App() {
  return (
    <div>
      <h1 className="title">Work History</h1>
      <VerticalTimeline>
        {timelineElements.map((element) => {
          return (
            <VerticalTimelineElement
              key={element.key}
              date={element.date}
              dateClassName="date"
              iconStyle={{ background: "#66fcf1 " }}
              icon={<WorkIcon />}
            >
              <div
                className="titlejt"
                style={{ background: "#353d43", borderRadius: 40 }}
              >
                <div className="text-center  ">
                  <div className="middle2">
                    <h2
                      style={{ color: "white", fontSize: 20 }}
                      className="vertical-timeline-element-title 
                    
                    "
                    >
                      <em> {element.title} </em>
                    </h2>
                  </div>

                  <div className="g mt-2 ">
                    <div className="middle2">
                      <p
                        style={{
                          color: "white",
                          fontSize: 20,
                          fontWeight: "bold",
                          marginBottom: 0,
                        }}
                        className="vertical-timeline-element-subtitle  rounded  "
                      >
                        <strong>
                          {" "}
                          <i> {element.company}</i>{" "}
                        </strong>
                      </p>
                    </div>

                    <div className="middle2">
                      <p
                        style={{
                          color: "white",
                          fontSize: 20,
                          fontWeight: "bold",
                          marginBottom: 0,
                          marginTop: 0,
                        }}
                        className="  "
                      >
                        <u>Location: {element.location}</u>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <p id="description">{element.description}</p> */}
              <ul>
                {element.description &&
                  element.description.map((desc) => {
                    return (
                      <li
                        style={{ marginTop: 10, marginBottom: 10 }}
                        key={desc}
                      >
                        {desc}
                      </li>
                    );
                  })}
              </ul>
            </VerticalTimelineElement>
          );
        })}
      </VerticalTimeline>
    </div>
  );
}

export default App;
